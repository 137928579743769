import React, { useState, useEffect } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useLocation, useHistory } from 'react-router-dom'
import { useErrorService, useUserService, Button } from '@abroad/components'
import { Container } from 'react-bootstrap'
import { PageHeader, GrayInfoCard } from '../common'
import API from '../../utils/API'

const IndividualSessionScheduling = () => {
  const [isEventTriggered, setIsEventTriggered] = useState(false)
  const [widgetHeight, setWidgetHeight] = useState('700px')
  const Error = useErrorService()
  const { user, setUser } = useUserService()
  const location = useLocation()
  const history = useHistory()
  const selectedCoachDetail = location.state?.selectedCoachDetail
  const groupId = selectedCoachDetail?.slotId
  const coachId = selectedCoachDetail?.coachId
  const growthPlanLink = selectedCoachDetail?.growthPlanLink

  useEffect(() => {
    if (!growthPlanLink) {
      history.goBack()
    }
  }, [growthPlanLink, history])

  const getSessionScheduling = async (scheduledUri) => {
    try {
      const { data } = await API.company.getIndividualSessionScheduling(
        groupId,
        coachId,
      )
      if (data) {
        setUser((prev) => ({
          ...prev,
          isInGroup: true,
          isGrowthPlanCreated: true,
        }))
        history.push('/schedule-detail', {
          dateAndTime: selectedCoachDetail?.dateAndTime,
          surveyType: location.state?.surveyType,
        })
      }
    } catch (e) {
      Error.showError(e)
    }
  }
  useCalendlyEventListener({
    onPageHeightResize: (event) => {
      const newHeight = event.data.payload.height
      setWidgetHeight(newHeight)
    },
    onEventScheduled: (e) => {
      const scheduledUri = e.data.payload.event.uri

      setIsEventTriggered(true)
      getSessionScheduling(scheduledUri)
    },
  })

  if (isEventTriggered) {
    history.push('/fetching-coaching-loading')
  }

  return (
    <>
      <Container className='px-0' fluid>
        <PageHeader title='Vanderbilt Coaching Circles' />
        <div>
          <h3 className='s3 mt-3'>
            Select a time for your 1:1 Growth Plan Session:
          </h3>
        </div>
        <div>
          <GrayInfoCard
            className='mb-2 mt-4 p-4 assessment-info-box circle-detail-box'
            titleFn={() => {
              return (
                <>
                  <p className='mb-3 s5 font-weight-500'>
                    Your 1:1 Growth Plan Session is a confidential coaching
                    session that will support you in identifying and clarifying
                    important personal and professional goals as you embark on
                    your journey at Vanderbilt. It is tailored to your needs and
                    will be based on your assessment.
                  </p>
                  <p className='mb-3 s5 font-weight-500'>
                    Most people report feeling inspired, empowered and motivated
                    towards their chosen goals after meeting with their coach
                    for their 1:1 Growth Plan Session. It’s a valuable time set
                    aside just for you.
                  </p>
                  <p className=' m-0 s5 font-weight-500'>
                    Each team member receives 1 session at the start of the
                    program with the same coach that will facilitate the
                    coaching circle that they select.
                  </p>
                </>
              )
            }}
          />
        </div>
        <div>
          <InlineWidget
            url={`${growthPlanLink}?hide_landing_page_details=1&hide_gdpr_banner=1&primary_color=bf9000`}
            styles={{
              height: widgetHeight,
              minHeight: widgetHeight,
            }}
            prefill={{
              email: user?.email,
              firstName: user?.fname,
              lastName: user?.lname,
              name: user?.fullName,
            }}
          />
        </div>
        <div className='row justify-content-between mt-32px'>
          <div className='col-lg-2 col-md-3 col-sm-4 col-5'>
            <Button
              className='text-uppercase border-radius-5 py-12px px-50px line-height-normal btn-abroad-blue w-100'
              onClick={() => history.goBack()}>
              BACK
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default IndividualSessionScheduling
