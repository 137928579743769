import React, { useEffect, useState } from 'react'
import Imgix from 'react-imgix'
import { Table } from 'react-bootstrap'

import API from '../utils/API'
import { useHistory, useParams } from 'react-router-dom'
import { PageHeader } from '../components/common'

import { useUserService, ABSpinner, useErrorService } from '@abroad/components'
import { Helmet } from 'react-helmet'
import SessionEvent from '../constants/events'
import TagManager from 'react-gtm-module'
import { PDFDownloadIcon, useStartMediaPlayer } from '../components'
import { getAssessmentCurrentData } from '../utils/date'

const SessionDetails = ({ pageUrl = '' }) => {
  const history = useHistory()
  // const { user } = useUserService()
  let { sessionId } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const { user } = useUserService()
  const userId = user?.id
  const [sessionData, setSessionData] = useState(null)
  const [mediaList, setMediaList] = useState([])
  const [showContent, setShowContent] = useState(false)
  const Error = useErrorService()
  const notesLength = 300

  const { setMediaParams } = useStartMediaPlayer({ disabledControls: true })

  const renderHelmet = () => (
    <Helmet>
      <title>{'Sessions-Details | Abroad'}</title>
      <meta name='title' content={'Sessions | Abroad'}></meta>
      <meta property='og:title' content={'Sessions | Abroad'}></meta>
      <meta property='twitter:title' content={'Sessions | Abroad'}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/coaching/session/${sessionId}`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/coaching/session/${sessionId}`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/coaching/session/${sessionId}`}></meta>
    </Helmet>
  )

  const openSessionDetailsAction = (sessionId) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SessionEvent.event.sessionDetails,
        eventProps: {
          category: SessionEvent.category.sessionDetailsCategory,
          action: 'User open session details',
          label: 'Open session details by user',
          value: `User open session details with sessionId - ${sessionId}.`,
          userId: user?.id,
        },
      },
    })
  }

  useEffect(() => {
    const getSessionForClient = async () => {
      setIsLoading(true)
      try {
        const responseData = await API.session.getSessionForClient(sessionId)
        //New Individual or Group Session Notes notification isRead API
        await API.user.hasNotificationRead({
          type: 1,
          uniqueId: sessionId,
        })
        await API.session.openSession({ isRead: true }, sessionId)
        openSessionDetailsAction(sessionId)
        setSessionData(responseData?.data)
        const { medias = [], mediaModules = [] } = responseData?.data
        setMediaList([...medias, ...mediaModules])
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        if (e?.code === 'forbidden') {
          Error.showError(e)
          history.replace('/home')
          return
        }
        Error.showError(e)
      }
    }
    if (sessionId) getSessionForClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  const downloadKeySkillHandler = (title) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SessionEvent.event.sessionKeySkills,
        eventProps: {
          category: SessionEvent.category.sessionKeySkillsCategory,
          action: 'User download recommended key skills',
          label: 'Download recommended key skills',
          value: `User download recommended key skills, title is ${title}`,
          userId,
        },
      },
    })
  }

  const favoritesAPI = ({
    oldIsFavorite,
    isProgram,
    mediaOrModuleId,
    subCategoryId,
    isFullModule,
  }) => {
    const favoriteObj = {
      isFavorite: !oldIsFavorite,
    }
    if (!isProgram) {
      return API.mediaLibrary.toggleFavorite(favoriteObj, mediaOrModuleId)
    }
    if (isFullModule) {
      return API.programs.toggleFavorite(favoriteObj, mediaOrModuleId)
    } else {
      return API.programs.favoriteModuleAudio(
        subCategoryId,
        mediaOrModuleId,
        favoriteObj,
      )
    }
  }

  const gtmToggleSessionMediaBookmarkAction = (
    mediaOrModuleId,
    oldIsFavorite,
  ) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SessionEvent.event.toggleSessionMediaBookmark,
        eventProps: {
          category: SessionEvent.category.toggleSessionMediaBookmarkCategory,
          action: 'User toggle session media bookmark',
          label: 'toggle session media bookmark',
          value: `User toggle session media bookmark, (mediaOrModuleId - ${mediaOrModuleId}, isFavorite - ${!oldIsFavorite})`,
          userId,
        },
      },
    })
  }

  const gtmPlaySessionMediaAction = (
    mediaOrModuleId,
    mediaSubCategoryId,
    isProgramMedia,
  ) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SessionEvent.event.ListenPSPAudio,
        eventProps: {
          category: SessionEvent.category.ListenPSPAudioCategory,
          action: 'User listen session attached audio',
          label: 'Listen attached audio',
          value: `User listen attached ${
            isProgramMedia ? 'module' : 'media library'
          } audio with media SubcategoryId - ${mediaSubCategoryId} , mediaOrModuleId - ${mediaOrModuleId} in sessions page`,
          userId: user?.id,
        },
      },
    })
  }

  const toggleIsFavorite = async (params) => {
    try {
      const failsafeMediList = [...mediaList]
      const clonedMediaList = [...mediaList]
      const index = clonedMediaList.findIndex(
        (e) => e._id === params.mediaOrModuleId,
      )
      clonedMediaList[index] = {
        ...clonedMediaList[index],
        isFavorite: !params.oldIsFavorite,
      }
      setMediaList(clonedMediaList)
      const { data } = await favoritesAPI(params)
      if (!data?.status) {
        setMediaList(failsafeMediList)
      } else {
        gtmToggleSessionMediaBookmarkAction(
          params?.mediaOrModuleId,
          params?.oldIsFavorite,
        )
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  const getTitleBySessionType = () => {
    if (sessionData?.isGrowthSession) {
      return 'Growth Plan Session'
    }
    return !!sessionData?.clientId ? 'Individual Session' : 'Group Session'
  }

  const renderSubTitle = (media) => {
    const isFullModule = media?.hasOwnProperty('parentCategory')
    let details
    if (isFullModule) {
      details = {
        label1: media?.parentCategory?.title,
        label2: media?.subTitle?.toLowerCase(),
      }
    } else {
      details = {
        label1: media?.categoryId?.title,
        label2: media?.subCategoryId?.title?.toLowerCase(),
      }
    }

    return (
      <div className='s11 d-flex text-dark-900-5 custom-ellipsis-text'>
        <div className='d-flex'>
          <span className='text-capitalize'>{details.label1}</span>
          <CircleDotSVG />
        </div>
        <span className='text-capitalize custom-ellipsis-text'>
          {details.label2}
        </span>
      </div>
    )
  }

  const CircleDotSVG = () => {
    return (
      <svg
        className='mx-1 align-self-center'
        xmlns='http://www.w3.org/2000/svg'
        width='4'
        height='4'
        viewBox='0 0 4 4'
        fill='none'>
        <circle cx='2' cy='2' r='2' fill='#000000' fill-opacity='50%' />
      </svg>
    )
  }

  if (isLoading) {
    return (
      <>
        {renderHelmet()}
        <PageHeader
          title='Sessions'
          version='small'
          backArrowAction={() => history.push('/coaching/sessions')}
          showBackArrow={true}
        />
        <ABSpinner />
      </>
    )
  }

  if (
    !sessionData?.postSession &&
    !mediaList?.length &&
    !sessionData?.keySkills?.length
  ) {
    return (
      <>
        {renderHelmet()}
        <PageHeader
          title='Sessions'
          version='small'
          backArrowAction={() => history.push('/coaching/sessions')}
          showBackArrow={true}
        />
        <div className='s1 mt-lg-0 mt-2'>
          {getTitleBySessionType()}
          {' - '}
          {getAssessmentCurrentData(sessionData?.date, 'MMMM Do YYYY')}
        </div>
        <div className='s5 mt-2'>No coach notes found!</div>
      </>
    )
  }

  const showFullContent = () => {
    setShowContent(!showContent)
  }

  return (
    <>
      {renderHelmet()}
      <PageHeader
        title='Sessions'
        version='small'
        backArrowAction={() => history.goBack()}
        showBackArrow={true}
      />
      <div className='s1 mt-lg-0 mt-2'>
        {getTitleBySessionType()}
        {' - '}
        {getAssessmentCurrentData(sessionData?.date, 'MMMM Do YYYY')}
      </div>
      <div className='mt-32px mb-52px'>
        {sessionData?.postSession && (
          <>
            <div className='s3'>Coach Notes</div>
            <div
              className='s5 mt-2 mb-0'
              dangerouslySetInnerHTML={{
                __html: showContent
                  ? sessionData?.postSession
                  : sessionData?.postSession.slice(0, notesLength),
              }}
            />
            {sessionData?.postSession.length > notesLength ? (
              <div
                className='cursor-pointer text-saffron-700 fw-bold'
                onClick={showFullContent}>
                {showContent ? 'Read less' : 'Read more'}
              </div>
            ) : null}
          </>
        )}
        {mediaList.length !== 0 && (
          <>
            <div className='s3 mt-40px mb-3'>Recommended Learning</div>
            <hr className='my-0 opacity-100 custom-gray-100 abroad-table-border' />
            <div className='table-responsive'>
              <Table className='custom-table align-middle table-hover'>
                <tbody>
                  {mediaList.map((media, index) => (
                    <tr
                      className='cursor-pointer'
                      onClick={() => {
                        gtmPlaySessionMediaAction(
                          media._id,
                          media?.subCategory?._id ||
                            media?.subCategoryId?._id ||
                            media?.subCategoryId,
                          media?.isProgram,
                        ) //(mediaOrModuleId, mediaSubCategoryId, isProgramMedia)
                        setMediaParams({
                          mediaId: media._id,
                          mediaSubCategoryId:
                            media?.subCategory?._id ||
                            media?.subCategoryId?._id ||
                            media?.subCategoryId,
                          isProgramMedia: media?.isProgram,
                          isFullModule: media?.hasOwnProperty('parentCategory'),
                        })
                      }}>
                      <td width='5%' className='hover:td-play-icon'>
                        <span>{index + 1}</span>
                        <span className='icon icon-play font-16 icon-outline-gold text-start'></span>
                      </td>
                      <td width='40%'>
                        <div className='d-flex'>
                          <Imgix
                            className='border-radius-5'
                            imgixParams={{ fit: 'scale', w: 42, h: 42 }}
                            src={`${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}`}
                            height={48}
                            width={48}
                            alt='image'
                          />
                          <div className='ms-2 media-details d-flex flex-column w-100'>
                            <div className='d-flex'>
                              <span className='font-16 text-start me-2 mb-1 align-self-center'></span>
                              <span className='mb-1 custom-ellipsis-text'>
                                {media?.title}
                              </span>
                            </div>
                            <div className='ms-2'>{renderSubTitle(media)}</div>
                          </div>
                        </div>
                      </td>
                      <td width='25%'>{media?.expert}</td>
                      <td width='23%'>
                        {' '}
                        {media?.duration
                          ? `${Math.round(media?.duration / 60)} Mins`
                          : ''}
                      </td>
                      <td width='7%' className='text-end pe-3'>
                        <span
                          className={`icon icon-bookmark${
                            media?.isFavorite
                              ? '-gold'
                              : ' hover:text-saffron-700'
                          } cursor-pointer`}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            toggleIsFavorite({
                              oldIsFavorite: media?.isFavorite,
                              mediaOrModuleId: media?._id,
                              subCategoryId: media?.subCategoryId?._id,
                              isProgram: media?.isProgram,
                              isFullModule:
                                media?.hasOwnProperty('parentCategory'),
                            })
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        {sessionData?.keySkills.length > 0 && (
          <div className='s3 mt-3 mb-3'>Key Skills</div>
        )}
        {Array.isArray(sessionData?.keySkills) &&
          sessionData?.keySkills?.map((pdfData) => (
            <Table>
              <tr className='cursor-pointer'>
                <td>
                  <div className='d-flex ms-1'>
                    <PDFDownloadIcon
                      pdfData={pdfData}
                      downloadKeySkillHandler={downloadKeySkillHandler}
                    />
                    <div className='ms-2 media-details d-flex flex-column pdf-title'>
                      <div className='d-flex'>
                        <span className='mb-1 ms-2'>
                          {pdfData?.keySkill?.title}
                        </span>
                      </div>
                      <div className='s11 d-lg-flex text-dark-900-5 ms-2'>
                        {pdfData?.keySkill?.description}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </Table>
          ))}
      </div>
    </>
  )
}

export default SessionDetails
