import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { Button, useUserService } from '@abroad/components'
import { PageHeader, GrayInfoCard } from '../common'
import { formatDate } from '../../utils/date'
import { surveyType as type } from '../../utils/constants'
const ScheduledDetail = () => {
  const history = useHistory()
  const { setUser } = useUserService()
  const location = useLocation()
  const dateAndTime = location.state?.dateAndTime
  const onlyInGroup = location.state?.onlyInGroup
  useEffect(() => {
    if (!dateAndTime) {
      history.replace('/survey')
    }
  }, [dateAndTime, history])

  useEffect(() => {
    if (onlyInGroup) {
      setUser((prev) => ({
        ...prev,
        isInGroup: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyInGroup])

  return (
    <>
      <Container fluid>
        <PageHeader
          showBackArrow={true}
          version='small'
          title='Coach & Circles'
          backArrowAction={() =>
            history.replace(
              location.state?.surveyType === type.WISDOM
                ? '/wisdom'
                : '/survey',
            )
          }
        />
        <h3 className='s1 mt-24px' style={{ letterSpacing: '0px' }}>
          Vanderbilt Coaching Circles
        </h3>
        <div>
          {dateAndTime && (
            <div className='my-4 d-flex flex-column'>
              <GrayInfoCard
                className='p-4 assessment-info-box w-100 mb-62px'
                titleFn={() => {
                  return (
                    <>
                      <p className='mb-3 s5 font-weight-500'>
                        Your first Coaching Circle will take place on{' '}
                        {formatDate(dateAndTime[0]?.dates[0])} from{' '}
                        {dateAndTime[0]?.from} to {dateAndTime[0]?.to} and your
                        coach will send you a calendar invite.
                      </p>
                      {!onlyInGroup && (
                        <p className='mb-0 s5 font-weight-500'>
                          You will also receive an automated notification to
                          confirm your 1:1 Growth Plan Session.
                        </p>
                      )}
                    </>
                  )
                }}
              />
              <div className='d-flex justify-content-end mb-3'>
                <Button
                  variant='saffron'
                  className='text-uppercase border-radius-normal text-white py-12px px-50px'
                  onClick={() => {
                    history.replace(
                      onlyInGroup
                        ? '/home'
                        : location.state?.surveyType === type.WISDOM
                        ? '/wisdom'
                        : '/survey',
                    )
                  }}>
                  {onlyInGroup ? 'GO TO HOME' : 'View Assessment Result'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

export default ScheduledDetail
