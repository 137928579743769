import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import Imgix from 'react-imgix'
import { PlayIcon } from '@abroad/components'
import { ABSwiperSlider } from '../slider'
import { FancyboxPopup } from '../common'

const QuestsLearningResourcesVideo = ({ category }) => {
  const { questId } = useParams()
  const renderVideoMedia = (videoMedia, style = {}) => {
    return (
      <div key={videoMedia.id}>
        <div
          className='cursor-pointer'
          style={style}
          onClick={() => {
            FancyboxPopup(videoMedia.url)
          }}>
          <div className='position-relative border-radius-10 overflow-hidden'>
            <Imgix
              className='lazyload border-radius-10 img-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                w: 400,
                h: 400,
                ar: '1:1',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${videoMedia.thumbnail}`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${videoMedia.thumbnail}?fit=crop&fill=blur&h=400&w=400&ar=1:1&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10 hover:bg-black-100 hover:bg-opacity-75 breakthrough-media-card'>
              <PlayIcon className='play-icon' />
            </div>
          </div>
        </div>
        <div className='d-block mt-15px mb-3px'>
          <div className={classNames('s7 text-truncate')}>
            {videoMedia.title}
          </div>
        </div>
        <div className='s11 font-saffron-700'>
          {videoMedia.timeInMinute} minutes
        </div>
      </div>
    )
  }
  return (
    <>
      <Container className={classNames(`p-0 mb-52px`)} fluid>
        <Row className='g-0'>
          <Col className='g-0'>
            <div className='s3 mb-3'>Video</div>
            <ABSwiperSlider containerClassName='card-swiper'>
              {category.map((videoMedia) => renderVideoMedia(videoMedia))}
            </ABSwiperSlider>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default QuestsLearningResourcesVideo
