import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService, useUserService } from '@abroad/components'
import { FreePlanNoSurvey, PageHeader } from '../components'
import API from '../utils/API'
import { surveyType } from '../utils/constants'
import { localStorageCustomSurvey } from '../utils/constants'
import { sortedByDate } from '../utils/date'

const type = 'personal'

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{'Awareness Assessment | Abroad'}</title>
      <meta name='title' content={'Awareness Assessment | Abroad'}></meta>
      <meta
        property='og:title'
        content={'Awareness Assessment | Abroad'}></meta>
      <meta
        property='twitter:title'
        content={'Awareness Assessment | Abroad'}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/survey`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/survey`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/survey`}></meta>
    </Helmet>
  )
}

const renderPageHeader = () => <PageHeader title='Awareness Assessment' />

const NoAwarenessAssessment = () => {
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  const { user } = useUserService()
  const history = useHistory()
  const customSurveyRequestId = localStorage.getItem(localStorageCustomSurvey)

  useEffect(() => {
    if (customSurveyRequestId) {
      history.push(`/custom360?csrId=${customSurveyRequestId}`)
    }
  }, [customSurveyRequestId, history])

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await API.survey.getSurveys(type, surveyType.AWARENESS) // (surveyType, filterBy)
        if (data && data?.surveys.length > 0) {
          const sortedSurveys = sortedByDate(data?.surveys, 'createdAt') // (data, key)
          history.push(`/survey/${sortedSurveys[0]?._id}`)
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (user?.surveyTaken) {
      getSurveys()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      {renderHelmet()}
      <main>
        {renderPageHeader()}
        <FreePlanNoSurvey isRenderOnAwarenessPage={true} />
      </main>
    </>
  )
}

export default NoAwarenessAssessment
