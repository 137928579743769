import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner, useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import QuestsLearningResourcesAudio from './QuestsLearningResourcesAudio'
import API from '../../utils/API'
import QuestsLearningResourcesVideo from './QuestsLearningResourcesVideo'
import QuestsLearningResourcesBlog from './QuestsLearningResourcesBlog'
const QuestsLearningResources = () => {
  const { questId } = useParams()
  const Error = useErrorService()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [blogs, setBlogs] = useState([])
  const [medias, setMedias] = useState([])
  const [videos, setVideos] = useState([])
  useEffect(() => {
    const getAllBlogCategories = async () => {
      try {
        const { data } = await API.quests.getQuestMedias(questId)
        if (data) {
          setBlogs(data.blogs)
          setMedias(data.medias)
          setVideos(data.videos)
          setIsLoading(false)
        } else {
          setBlogs([])
          setMedias([])
          setVideos([])
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    getAllBlogCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])
  return (
    <>
      <Helmet>
        <title>{'Learning Resources | Abroad'}</title>
        <meta name='title' content={'Learning Resources | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Learning Resources | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Learning Resources | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>
      <PageHeader
        showBackArrow={true}
        version='small'
        title='Your Quest'
        backArrowAction={() => history.goBack()}
      />
      <div className='s1 py-16px'>Learning Resources</div>
      {isLoading ? (
        <ABSpinner />
      ) : (
        <>
          {medias.length > 0 && (
            <QuestsLearningResourcesAudio
              category={medias}
              rowMarginBottom='mb-52px'
            />
          )}
          {videos.length > 0 && (
            <QuestsLearningResourcesVideo
              category={videos}
              rowMarginBottom='mb-52px'
            />
          )}
          {blogs.length > 0 && (
            <QuestsLearningResourcesBlog
              category={blogs}
              questId={questId}
              rowMarginBottom='mb-52px'
            />
          )}
        </>
      )}
    </>
  )
}

export default QuestsLearningResources
