import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import API from '../../utils/API'
import { ABSpinner, useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import Imgix from 'react-imgix'

const QuestsYour = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState()
  const [questsHome, setQuestsHome] = useState({})
  const Error = useErrorService()
  const history = useHistory()
  useEffect(() => {
    const getCount = async () => {
      try {
        const { data } = await API.quests.getToDoListCount(questsHome._id)
        setCount(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (questsHome?._id) getCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questsHome?._id])

  useEffect(() => {
    const getQuestsHome = async () => {
      try {
        const { data: quest } = await API.quests.questsHomeGet()
        if (Object.keys(quest).length === 0) {
          const { data: questAll } = await API.quests.allQuest()
          setQuestsHome(questAll[0])
          setIsLoading(false)
        } else {
          setQuestsHome(quest)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    getQuestsHome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const yourQuests = [
    {
      title: 'To Do List',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/TodoList.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/todos` : '',
      count: count?.todoListCount > 0 && count?.todoListCount,
    },
    {
      title: 'Learning Resources',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Learning.png`,
      link: questsHome?._id
        ? `/your-quest/${questsHome?._id}/learning-resources`
        : '',
    },
    {
      title: 'Intentions & Insights',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Intentions.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/intentions` : '',
      count: count?.intensionCount > 0 && count?.intensionCount,
    },
    {
      title: 'Faculty',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Faculty.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/faculties` : '',
      count: count?.facultyCount > 0 && count?.facultyCount,
    },
    {
      title: 'Itinerary',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Itinerary.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/itinrary` : '',
    },
  ]

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <PageHeader title='Home' />
      <div className='s1 pt-32px'>
        Welcome To Your <br /> Bhutan Quest Dashboard
      </div>
      <div>
        <Imgix
          className='border-radius-10 mt-40px mb-26px lazyload imgix-object-cover'
          imgixParams={{
            fit: 'crop',
            crop: 'edges',
            ar: '4:1',
          }}
          src={`${process.env.REACT_APP_IMG_SOURCE}/QuestMainWeb.jpg`}
          width={'100%'}
          alt='image'
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
          htmlAttributes={{
            src: `${process.env.REACT_APP_IMG_SOURCE}/QuestMainWeb.jpg?ar=4:1&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
          }}
        />
      </div>
      <div>
        {yourQuests.map((task) => (
          <div onClick={() => history.push(task.link)}>
            <div className='cursor-pointer cursor-hover py-16px d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <div className='pr-16px'>
                  <Image
                    src={task.imageUrl}
                    className='border-radius-10'
                    style={{ height: '60px', width: '60px' }}
                    alt='image'
                  />
                </div>
                {task.count && (
                  <div className='numerical-msg rounded-circle s-captions d-flex align-items-center justify-content-center me-1'>
                    {task.count}
                  </div>
                )}
                <div className='s6 font-hover'>{task.title}</div>
              </div>
              <div className='icon icon-right-arrow s9'></div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default QuestsYour
