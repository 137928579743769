import React, { useContext, useEffect } from 'react'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'
import sysend from 'sysend'
import { useErrorService, useUserService } from '@abroad/components'
import ProgramEnrollBtn from './ProgramEnrollBtn'
import NotificationProvider from './contexts/NotificationProvider'
import { JournalIcon, SidebarAbroadIcon } from '../assetsComponents'
import { ProfileDropdown } from '../settings'
import { NotificationDropDown } from '../settings'
import { LayoutContext } from '../../utils/contexts'
import API from '../../utils/API'
import AuthService from '../../utils/AuthService'

const Header = () => {
  const { showSidebar, setShowSidebar } = useContext(LayoutContext)

  let history = useHistory()
  const { user, setUser } = useUserService()
  const Error = useErrorService()

  useEffect(() => {
    if (!user) {
      history.push('/', {})
    }
  }, [user, history])

  const onLogout = async () => {
    try {
      await API.onboarding.logout()
      if (user.isAdmin || user.isCoach) {
        const iframeRoot = document.getElementById('iframe-root')
        const i = document.createElement('iframe')
        i.style.display = 'none'
        i.src = `${process.env.REACT_APP_ADMIN_DOMAIN}/signout`
        iframeRoot.appendChild(i)
      }
      setUser(null)
      AuthService.removeData()
      sysend.broadcast('client_logout', { message: 'client_logout' })
    } catch (e) {
      Error.showError(e)
    }
  }
  return (
    <NotificationProvider>
      <Navbar className='d-none d-lg-flex nav-header py-0'>
        <Navbar.Brand
          className={classnames('position-fixed ps-0', {
            'd-none': showSidebar,
            'd-lg-flex': !showSidebar,
          })}></Navbar.Brand>
        <Navbar.Collapse className='justify-content-end py-2 ms-2'>
          <ProgramEnrollBtn />
          <div className='me-3 d-flex align-items-center align-self-center'>
            <JournalIcon
              active={history.location.pathname === '/journal'}
              className='journal-icon'
              onClick={() => history.push('/journal')}
            />
          </div>
          <div className='notification-wrapper'>
            <NotificationDropDown showSidebar={showSidebar} />
          </div>
          <ProfileDropdown onLogout={onLogout} />
        </Navbar.Collapse>
      </Navbar>
      {/* Mobile header */}
      <Navbar
        fixed='top'
        className='d-flex d-lg-none bg-gray-100 align-items-center px-3 py-2'>
        <Navbar.Brand className='me-3 py-0'>
          <SidebarAbroadIcon onClick={() => history.push('/home')} />
        </Navbar.Brand>
        <Navbar.Collapse className='position-relative justify-content-end'>
          <Navbar.Text className='d-flex'>
            <div className='notification-wrapper d-flex'>
              {!showSidebar && <ProgramEnrollBtn />}
              <div className='me-3 d-flex align-items-center align-self-center'>
                <JournalIcon
                  active={history.location.pathname === '/journal'}
                  className='journal-icon'
                  onClick={() => history.push('/journal')}
                />
              </div>
              <NotificationDropDown classes='ms-4' showSidebar={showSidebar} />
            </div>
            {showSidebar && <ProfileDropdown onLogout={onLogout} />}
            <span
              className={classnames(
                'icon icon-hamburger text-black cursor-pointer align-self-center ms-4 font-20',
                {
                  'd-none': showSidebar,
                },
              )}
              onClick={() => setShowSidebar(true)}
            />
            <span
              className={classnames(
                'icon icon-menu-left-arrow text-black cursor-pointer ms-4 font-24',
                {
                  'd-none': !showSidebar,
                },
              )}
              onClick={() => setShowSidebar(false)}
            />
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </NotificationProvider>
  )
}

const HeaderWrp = (props) => {
  if (props.matched) {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='custom-col-lg-8 custom-col-10 custom-offset-lg-1'>
            <Header {...props} />
          </div>
        </div>
      </div>
    )
  } else {
    return <Header {...props} />
  }
}

export default HeaderWrp
