import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { toWords } from 'number-to-words'
import { Helmet } from 'react-helmet'
import Imgix from 'react-imgix'
import { ABSpinner, useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import API from '../../utils/API'
import { formateMonthDate, formateAmPm } from '../../utils/date'

const QuestsItinerary = () => {
  const history = useHistory()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [inquiries, setInquiries] = useState([])
  const { questId } = useParams()

  useEffect(() => {
    const getQuestsItinerary = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.quests.questsItinerary(questId)
        setInquiries(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getQuestsItinerary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  return (
    <>
      <Helmet>
        <title>{'Itinerary | Abroad'}</title>
        <meta name='title' content={'Itinerary | Abroad'}></meta>
        <meta property='og:title' content={'Itinerary | Abroad'}></meta>
        <meta property='twitter:title' content={'Itinerary | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>
      <div>
        <PageHeader
          showBackArrow={true}
          version='small'
          title='Your Quest'
          backArrowAction={() => history.goBack()}
        />
        <div className='s1 py-16px'>Itinerary</div>

        {isLoading ? (
          <ABSpinner />
        ) : (
          <>
            {inquiries.days && inquiries.days.length > 0 ? (
              inquiries.days.map((section, index) => (
                <div className='mb-25px'>
                  <Row className='align-items-center'>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Imgix
                        className='border-radius-10 w-100 mb-3 img-100 lazyload img-cover'
                        imgixParams={{
                          fit: 'crop',
                          crop: 'faces',
                          ar: '4:1',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${section.imageFileName}`}
                        alt='image'
                        attributeConfig={{
                          src: 'data-src',
                          srcSet: 'data-srcset',
                          sizes: 'data-sizes',
                        }}
                        htmlAttributes={{
                          src: `${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${section.imageFileName}?ar=4:1&fit=crop&crop=faces&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                        }}
                      />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className='s10a font-saffron-700'>
                        {formateMonthDate(section.date)}
                      </div>
                      <div className='s1 mt-1'>
                        <span className='text-capitalize'>
                          {`Day ${toWords(index + 1)}`} -{' '}
                        </span>
                        {section.subTitle}
                      </div>
                    </Col>
                  </Row>
                  <div className='table-responsive'>
                    <table className='table custom-table align-middle table-hover table-width'>
                      <tbody className='cursor-pointer'>
                        {section.itineraries.map((event, index) => (
                          <tr
                            className='align-middle'
                            style={{
                              borderBottom:
                                index === section.itineraries.length - 1
                                  ? 'none'
                                  : '1px solid #D9D9D9',
                            }}>
                            <td className='px-0 py-md-4 py-sm-3 py-3 w-20'>
                              <div className='s8 fw-bold'>
                                {formateAmPm(event.fromTime, 'HH:mm')}
                                {event.toTime
                                  ? ` - ${formateAmPm(event.toTime, 'HH:mm')}`
                                  : index === section.itineraries.length - 1 &&
                                    !event.toTime
                                  ? ' onwards'
                                  : ''}
                              </div>
                            </td>
                            <td className='px-0 py-md-4 py-sm-2 py-2 w-80'>
                              <div className='s8'>{event.itinerary}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            ) : (
              <div className='s5 text-center'>
                There is no itinerary available at the moment.
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default QuestsItinerary
