import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { localStorageCustomSurvey } from '../../utils/constants'

const BackIcon = () => {
  return (
    <div className='ms-3 mt-3 d-flex align-items-center position-fixed'>
      <Link to='/home'>
        <div className='go-back-icon me-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'>
            <path
              d='M11 1.41406L4 8.20845'
              stroke='white'
              stroke-width='2'
              stroke-linecap='round'
            />
            <path
              d='M11 14.9975L4 8.20312'
              stroke='white'
              stroke-width='2'
              stroke-linecap='round'
            />
          </svg>
          <svg
            width='16'
            height='16'
            viewBox='0 0 24 43'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
              fill='white'
            />
          </svg>
        </div>
      </Link>
      <Link
        to='/home'
        onClick={() => localStorage.removeItem(localStorageCustomSurvey)}
        className='text-decoration-none'>
        <span className='s-captions font-white'>
          Return to the Abroad Platform
        </span>
      </Link>
    </div>
  )
}

export default BackIcon
