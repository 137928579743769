import React, { useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import Imgix from 'react-imgix'
import { PlayIcon } from '@abroad/components'
import { LayoutContext } from '../../utils/contexts'
import { ABSwiperSlider } from '../slider'
import { useStartMediaPlayer } from '../media'

const QuestsLearningResourcesAudio = ({ category }) => {
  const { setContextJourneyId } = useContext(LayoutContext)
  const { setMediaParams } = useStartMediaPlayer({ disabledControls: true })
  const renderSubcategoryMedia = (media, style = {}) => {
    return (
      <div key={media.id}>
        <div
          className='cursor-pointer'
          style={style}
          onClick={(e) => {
            setContextJourneyId(null)
            setMediaParams({
              mediaId: media?._id,
              mediaSubCategoryId: media?.subCategoryId?._id,
              isProgramMedia: media?.isProgram,
            })
          }}>
          <div className='position-relative border-radius-10 overflow-hidden'>
            <Imgix
              className='lazyload border-radius-10 img-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                w: 400,
                h: 400,
                ar: '1:1',
              }}
              src={
                media.isProgram
                  ? `${process.env.REACT_APP_IMG_SOURCE}/${media.subCategoryId.imageFilename}`
                  : `${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}`
              }
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}?fit=crop&fill=blur&h=400&w=400&ar=1:1&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10 hover:bg-black-100 hover:bg-opacity-75 breakthrough-media-card'>
              <PlayIcon className='play-icon' />
            </div>
          </div>
        </div>
        <div className='d-block mt-15px mb-3px'>
          <div
            className={classNames('s7 text-truncate', {
              'cursor-pointer': media.isUpgradeRequired,
            })}>
            {media.title}
          </div>
        </div>
        <div className='s11 font-saffron-700'>
          {Math.round(media.duration / 60)} minutes
        </div>
      </div>
    )
  }
  return (
    <>
      <Container className={classNames(`p-0 mb-52px`)} fluid>
        <Row className='g-0'>
          <Col className='g-0'>
            <div className='s3 mb-3'>Audio</div>
            {/* <div className='s3 mb-3'>{category.title}</div> */}
            <ABSwiperSlider containerClassName='card-swiper'>
              {category.map((media) => renderSubcategoryMedia(media))}
            </ABSwiperSlider>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default QuestsLearningResourcesAudio
