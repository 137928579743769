import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useErrorService } from '@abroad/components'
import Imgix from 'react-imgix'
import {
  PageHeader,
  QuestsCard,
  SwiperSlideQuests,
  SwiperSlideQuestsHighlights,
} from '../components'
import { useResolutionService } from '../hooks'
import API from '../utils/API'
import { ShimmerContentBlock } from 'react-shimmer-effects'

const Quests = () => {
  const { isMDScreen } = useResolutionService()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [upcomingQuests, setUpcomingQuests] = useState([])

  useEffect(() => {
    const getUpcoming = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.quests.upcomingQuests()
        setUpcomingQuests(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getUpcoming()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{'Quests | Abroad'}</title>
        <meta name='title' content={'Quests | Abroad'}></meta>
        <meta property='og:title' content={'Quests | Abroad'}></meta>
        <meta property='twitter:title' content={'Quests | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>

      <main>
        <PageHeader title='Quests' />
        <div>
          <div>
            <Imgix
              className='border-radius-10 mt-32px lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:2',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticQuestMain.png`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${
                  process.env.REACT_APP_IMG_SOURCE
                }/StaticQuestMain.png?ar=${
                  isMDScreen ? '2:1' : '5:2'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h3 className='s1 mt-24px' style={{ letterSpacing: '0px' }}>
              Wisdom Quests for Visionary Leaders
            </h3>
            <p className='pt-16px s5' style={{ lineHeight: '1.875rem' }}>
              At Abroad, we believe certain types of leadership cannot be taught
              in the classroom or through coaching. That’s why we’ve partnered
              with visionary universities to create immersive educational
              programs that integrate coaching, experiential learning, and
              wisdom from luminary leaders across cultures. Explore our
              life-changing Quests below and discover your true leadership
              potential.
            </p>
          </div>
          <div>
            <h1 className='mt-52px s3 mb-0'>How Our Quests Work </h1>
            <SwiperSlideQuests />
          </div>
          <div>
            <h1 className='mt-52px s3 mb-0'>Butan Quest Highlights </h1>
            <SwiperSlideQuestsHighlights />
          </div>
          <div>
            {isLoading ? (
              <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={370}
                thumbnailHeight={370}
              />
            ) : (
              <>
                {upcomingQuests.length === 0 ? null : (
                  <h1 className='mt-52px s3 mb-0'>Explore Our Quests </h1>
                )}
                {upcomingQuests.map((item) => (
                  <QuestsCard item={item} />
                ))}
              </>
            )}
          </div>
          <div className='mt-32px bg-abroad-blue w-100 py-3 px-lg-5 text-center d-flex justify-content-center rounded'>
            <a
              className='s4 text-white text-decoration-none mx-lg-5'
              target='blank'
              href='https://abroad.io/explore-wisdom-quests'>
              Click here to learn about our custom quests to destinations such
              as Peru, New Zealand and Mongolia
            </a>
          </div>
        </div>
      </main>
    </>
  )
}

export default Quests
