import React from 'react'
import { Spinner } from 'react-bootstrap'
import { OnboardTitle } from '../components/onboarding'
import { PageHeader } from '../components/common'

const FetchingCoachLoading = () => {
  const isLoading = true

  return (
    <main>
      <PageHeader title='Vanderbilt Coaching Circles' />
      <div className='d-flex justify-content-center align-items-center mt-lg-80px mt-5'>
        <div className='generating-results text-center'>
          <span>
            {isLoading && (
              <Spinner
                size='lg'
                animation='border'
                className='text-saffron-700 bt-spinner mb-38px'
                role='status'
              />
            )}
          </span>
          <OnboardTitle className='s5 text-black'>
            Please wait a moment...
          </OnboardTitle>
        </div>
      </div>
    </main>
  )
}

export default FetchingCoachLoading
