import React from 'react'
import Imgix from 'react-imgix'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/pagination/pagination.scss'
import '../../styles/customSwiper.scss'
import { useResolutionService } from '../../hooks'

const SwiperSlideQuestsHighlights = () => {
  const { isMDScreen } = useResolutionService()
  return (
    <div>
      <Swiper
        slidesPerView={3}
        navigation
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 2.5,
            spaceBetween: 40,
          },
          2560: {
            slidesPerView: 2.5,
            spaceBetween: 80,
          },
        }}
        className='overflow-xy custom-swiper'>
        <SwiperSlide>
          {' '}
          <div style={{ width: '100%' }}>
            <Imgix
              className='border-radius-10 mt-4 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:3',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights1.png`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights1.png?ar=${
                  isMDScreen ? '2:1' : '5:3'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h4 className='s10a pt-16px font-saffron-700'>EDUCATION</h4>
            <p className='s6'>
            Enjoy world-class education sessions with luminary leaders, coaches, and wisdom keepers to cultivate greater wisdom, purpose and fulfillment.
            </p>{' '}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <div style={{ width: '100%' }}>
            <Imgix
              className='border-radius-10 mt-4 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:3',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights2.png`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights2.png?ar=${
                  isMDScreen ? '2:1' : '5:3'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h4 className='s10a mt-3 font-saffron-700'>IMMERSION</h4>
            <p className='s6'>
            Experience our destinations as few ever have. Guided by our local partners, you'll delve deep into the wisdom of ancient cultures for a truly immersive experience that fosters lasting change.
            </p>{' '}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <div style={{ width: '100%' }}>
            <Imgix
              className='border-radius-10 mt-4 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:3',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights3.png`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights3.png?ar=${
                  isMDScreen ? '2:1' : '5:3'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h4 className='s10a mt-3 font-saffron-700'>COMMUNITY</h4>
            <p className='s6'>
            Forge lasting bonds with a diverse group of leaders. Our quests facilitate deep relationships that go beyond professional networking, leading to lifelong friendships and collaborations.
            </p>{' '}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <div style={{ width: '100%' }}>
            <Imgix
              className='border-radius-10 mt-4 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:3',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights4.png`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/StaticHighlights4.png?ar=${
                  isMDScreen ? '2:1' : '5:3'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h4 className='s10a mt-3 font-saffron-700'>PREPARATION & INTEGRATION</h4>
            <p className='s6'>
            Maximize your immersive educational experience with pre- and post-quest coaching and group sessions, along with thought-provoking educational content to understand the true wisdom of Bhutan.
            </p>{' '}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default SwiperSlideQuestsHighlights
