import React, { useState, useEffect } from 'react'
import Imgix from 'react-imgix'
import { useHistory } from 'react-router-dom'
import { useErrorService } from '@abroad/components'
import { useResolutionService } from '../../hooks'
import { ModuleMiniDetails } from '../breakthrough'
import API from '../../utils/API'

const QuestsHome = () => {
  const { isMDScreen } = useResolutionService()
  const [questsHome, setQuestsHome] = useState([])
  const history = useHistory()
  const Error = useErrorService()
  const [expanded, setExpanded] = useState(false)
  const currentDate = new Date()
  const startDate = new Date(questsHome.startDate)
  const endDate = new Date(questsHome.endDate)

  useEffect(() => {
    const getQuestsHome = async () => {
      try {
        const { data } = await API.quests.questsHomeGet()
        setQuestsHome(data)
      } catch (e) {
        Error.showError(e)
      }
    }
    getQuestsHome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDay = (dateString) => {
    const date = new Date(dateString)
    return date.getUTCDate()
  }

  const startDay = getDay(startDate)
  const endDay = getDay(endDate)

  if (!questsHome || Object.keys(questsHome).length === 0) {
    return null
  }

  const isInProgress = currentDate >= startDate && currentDate <= endDate

  const toggleExpand = () => {
    setExpanded(!expanded)
  }
  const getDescription = () => {
    if (expanded) {
      return questsHome.description
    } else {
      return questsHome.description.length > 550
        ? questsHome.description.slice(0, 550) + '...'
        : questsHome.description
    }
  }

  return (
    <div>
      {questsHome && (
        <div>
          <div className='s3 mt-32px '>
            {isInProgress ? 'Your Quest' : 'Your Upcoming Quest'}
          </div>
          <div
            onClick={() => {
              const questUrl = `/your-quest`
              history.push({
                pathname: questUrl,
                state: { month: questsHome.month, year: questsHome.year },
              })
            }}>
            <div className='row g-0 program-wrp cursor-pointer border-radius-5 p-32px custom-gray-bg-100 mt-24px'>
              <div className='col col-lg-4 col-12'>
                <div className='position-relative overflow-hidden border-radius-5'>
                  <Imgix
                    className='border-radius-5 img-cover w-100 program-img-zoom lazyload'
                    imgixParams={{
                      fit: 'crop',
                      crop: 'edges',
                      ar: isMDScreen ? '3:1' : '4:5',
                    }}
                    src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questsHome._id}/${questsHome.imageFileName}`}
                    alt='image'
                    attributeConfig={{
                      src: 'data-src',
                      srcSet: 'data-srcset',
                      sizes: 'data-sizes',
                    }}
                    htmlAttributes={{
                      src: `${process.env.REACT_APP_IMG_SOURCE}/quest/${
                        questsHome._id
                      }/${questsHome.imageFileName}
                      ?ar=${
                        isMDScreen ? '3:1' : '4:5'
                      }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`,
                    }}
                  />
                  <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10'></div>
                </div>
              </div>
              <div className='col col-lg-8 col-12 pl-lg-32px pt-lg-0 pt-3 d-flex flex-column justify-content-center'>
                <ModuleMiniDetails
                  className=''
                  showDots={false}
                  details={{
                    label1: `${questsHome.month}`,
                    label2: `${startDay}-${endDay},`,
                    label3: `${questsHome.year}`,
                  }}
                />
                <div className='s2 my-12px'>
                  <span className='fst-italic'>{questsHome.title}</span>
                </div>
                <pre
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleExpand()
                  }}
                  className={`s7 mb-0 pre`}
                  dangerouslySetInnerHTML={{ __html: getDescription() }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default QuestsHome
