import React, { useState, useEffect, useRef } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'
import { Button, useErrorService, useUserService } from '@abroad/components'
import { useLocation, useHistory } from 'react-router-dom'
import { string, object } from 'yup'
import { Form, Formik } from 'formik'
import API from '../../utils/API'
import { CloseIcon } from '@abroad/components'
import ApplyPromoCodeEvent from '../../constants/events'
import TagManager from 'react-gtm-module'
import CheckPromoCodeStatus from './CheckPromoCodeStatus'
import classNames from 'classnames'
import { getCookieByName, getCurrentExpiresDate } from '../../utils/utility'

const initValues = { promoCode: '' }

const ApplyPromoForm = React.forwardRef(
  ({ setPromoCodeResult, isPromoCodeApplied, planCode }, ref) => {
    const { user } = useUserService()
    const id = user?.id
    const [isLoading, setIsLoading] = useState(false)
    const Error = useErrorService()
    let { search, pathname } = useLocation()
    const history = useHistory()
    const query = new URLSearchParams(search)
    const networkId = query.get('nId')
    const [promoCode, setPromoCode] = useState(
      networkId ? 'Network' : query.get('promocode') || query.get('promo'),
    )
    const [promoApplied, setPromoApplied] = useState(false)
    const [initialValues, setInitialValues] = useState(initValues)
    const [networkPlan, setNetworkPlan] = useState(false)
    const validationSchema = object().shape({
      promoCode: string().required().matches('^[a-zA-Z0-9]+$'),
    })
    const resetRef = useRef()
    React.useImperativeHandle(ref, () => ({
      reset: () => {
        resetRef.current.click()
      },
    }))
    useEffect(() => {
      const promo = getCookieByName(
        `${process.env.REACT_APP_COOKIE_PREFIX}_promo_code`,
      )
      const localStoragePromocode = promo || null
      if (localStoragePromocode) {
        const obj = {
          promocode: localStoragePromocode,
        }
        if (planCode) {
          obj.plan = planCode
        }
        setPromoCode(localStoragePromocode)
        // onSubmit({ promoCode: localStoragePromocode })
        const params = new URLSearchParams(obj)
        history.replace({
          pathname: pathname,
          search: params.toString(),
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (promoCode || networkId) {
        if (promoCode) {
          setInitialValues({ promoCode: promoCode })
        }
        onSubmit({ promoCode: promoCode })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promoCode])

    const onSubmit = async (values) => {
      try {
        setIsLoading(true)
        setPromoApplied(false)
        setPromoCodeResult(null)
        let req
        if (networkId) {
          req = { networkId }
        } else {
          req = {
            promoCode: values.promoCode,
            planCode: planCode,
          }
        }
        const { data } = await API.payment.applyPromo(req)
        setIsLoading(false)
        if (data) {
          if (networkId) {
            setNetworkPlan(true)
          } else {
            setPromoApplied(true)
          }
          const currentExpires = getCurrentExpiresDate()
          document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_promo_code=;expires=${currentExpires};path=/`
          //GTM code
          TagManager.dataLayer({
            dataLayer: {
              event: ApplyPromoCodeEvent.event.applyPromoCode,
              eventProps: {
                category: ApplyPromoCodeEvent.category.applyPromoCodeCategory,
                action: 'User apply promo code',
                label: 'apply promo code',
                value: `User apply right promo code from Payment Page`,
              },
            },
          })
          setPromoCodeResult(data)
        }
      } catch (e) {
        setIsLoading(false)
        //GTM code
        TagManager.dataLayer({
          dataLayer: {
            event: ApplyPromoCodeEvent.event.applyPromoCode,
            eventProps: {
              category: ApplyPromoCodeEvent.category.applyPromoCodeCategory,
              action: 'User apply promo code',
              label: 'apply promo code',
              value: `User apply wrong promo code from Payment Page`,
              userId: id,
            },
          },
        })
        Error.showError(e)
      }
    }
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({
          handleSubmit,
          touched,
          errors,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <>
            <Form
              // ref={ref}
              onSubmit={handleSubmit}
              className='clearfix position-relative text-right mb-2-rem'>
              <InputGroup
                className={classNames('w-100 float-lg-right', {
                  'promo-group': promoApplied || networkPlan,
                })}>
                <FormControl
                  className='rounded-right-0 custom-text-uppercase apply-promo-input'
                  aria-label='promo-code'
                  aria-describedby='promo-code'
                  placeholder='Have a Promo Code?'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.promoCode}
                  name='promoCode'
                  isValid={touched?.promoCode && !errors?.promoCode}
                  isInvalid={touched?.promoCode && errors?.promoCode}
                  disabled={promoApplied || networkPlan}
                />
                <CheckPromoCodeStatus
                  render={(applyStatus) => {
                    isPromoCodeApplied(applyStatus)
                  }}
                />
                <Button
                  isLoading={isLoading}
                  disabled={isLoading || promoApplied || networkPlan}
                  type='submit'
                  className='px-2 px-lg-4 text-uppercase border-radius-3 apply-promo-button rounded-left-0'
                  variant='none'>
                  Apply
                </Button>
              </InputGroup>
              {promoApplied && (
                <span className='right-0 text-green-100 promo-text'>
                  Promo code applied&nbsp;&nbsp;
                  <span
                    ref={resetRef}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setPromoApplied(false)
                      setPromoCodeResult(null)
                      setFieldValue('promoCode', '')
                    }}>
                    <CloseIcon color='#000' />
                  </span>
                </span>
              )}
            </Form>
          </>
        )}
      </Formik>
    )
  },
)

export default ApplyPromoForm
