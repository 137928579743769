import React, { useEffect } from 'react'
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { useUserService } from '@abroad/components'
import {
  groupId,
  localStorageItemName,
  uniqueId,
  localStorageCustomSurvey,
  urlParamsForSurveyRequestId,
  customSurveyRequestId,
} from '../utils/constants'

const PublicRoute = ({ children, component: Component, render, ...rest }) => {
  const { user } = useUserService()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const querySurveyRequestId = query.get(urlParamsForSurveyRequestId)
  const queryCustomSurveyRequestId = query.get(customSurveyRequestId)
  const queryGroupId = query.get(groupId)
  const queryUniqueId = query.get(uniqueId)
  const history = useHistory()

  useEffect(() => {
    if (querySurveyRequestId) {
      localStorage.setItem(localStorageItemName, querySurveyRequestId)
    }
    if (queryCustomSurveyRequestId) {
      localStorage.setItem(localStorageCustomSurvey, queryCustomSurveyRequestId)
    }
    if (queryGroupId && queryUniqueId) {
      localStorage.setItem(groupId, queryGroupId)
      localStorage.setItem(uniqueId, queryUniqueId)
    }
  }, [
    querySurveyRequestId,
    queryGroupId,
    queryUniqueId,
    queryCustomSurveyRequestId,
  ])

  useEffect(() => {
    if (user && queryCustomSurveyRequestId) {
      history.push('/custom360')
    }
  }, [user, queryCustomSurveyRequestId, history])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (render) {
          render(props)
          return
        }
        if (user) {
          return <Redirect to={'/home'} />
        } else {
          return children || <Component {...props} />
        }
      }}
    />
  )
}

export default PublicRoute
