import React from 'react'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'

import Header from '../layout/Header'
import { GoBackIcon } from '../assetsComponents'

const versionEnum = {
  normal: 'normal',
  small: 'small',
}

const PageHeader = ({
  title,
  titleFn = () => {},
  version = versionEnum.normal,
  showBackArrow = false,
  backArrowAction = () => {},
  placement = 'bottom',
  wrapperClasses = '',
}) => {
  return (
    <Row
      className={classNames(
        'page-header',
        {
          'mt-3': versionEnum?.normal === version,
        },
        {
          'mt-4': versionEnum?.small === version,
        },
        wrapperClasses,
      )}>
      <Col className='text-start d-flex justify-content-between align-items-center'>
        <div>
          {version === versionEnum.normal ? (
            <div className='d-flex align-items-center'>
              {showBackArrow && (
                <GoBackIcon onClick={backArrowAction} className='me-2' />
              )}
              {title ? (
                <div className='s1' style={{ letterSpacing: '0px' }}>
                  {title}
                </div>
              ) : (
                titleFn()
              )}
            </div>
          ) : (
            <div className='d-flex align-items-center'>
              {showBackArrow && (
                <GoBackIcon onClick={backArrowAction} className='me-2' />
              )}
              <span className='s-captions text-capitalize'>{title}</span>
            </div>
          )}
        </div>
        <Header />
      </Col>
    </Row>
  )
}

export default PageHeader
