import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { AbroadGoldenIcon, LoginForm } from '../components'
import {
  localStorageItemName,
  groupId,
  urlParamsForSurveyRequestId,
  uniqueId,
} from '../utils/constants'
import { conditionMatched } from '../components/layout'

const Login = ({ location: { search }, history }) => {
  const query = new URLSearchParams(search)
  const querySurveyRequestId = query.get(urlParamsForSurveyRequestId)
  const queryGroupId = query.get(groupId)
  const queryUniqueId = query.get(uniqueId)

  useEffect(() => {
    if (querySurveyRequestId) {
      localStorage.setItem(localStorageItemName, querySurveyRequestId)
      history.replace('/')
    }
    if (queryGroupId && queryUniqueId) {
      localStorage.setItem(groupId, queryGroupId)
      localStorage.setItem(uniqueId, queryUniqueId)
      history.replace('/')
    }
  }, [querySurveyRequestId, queryGroupId, queryUniqueId, history])

  return (
    <>
      <Helmet>
        <title>{'Abroad Platform'}</title>
        <meta name='title' content={'Abroad Platform'}></meta>
        <meta property='og:title' content={'Abroad Platform'}></meta>
        <meta property='twitter:title' content={'Abroad Platform'}></meta>
      </Helmet>
      <main>
        <Container fluid className='px-0'>
          <Row className='vh-100 row g-0'>
            <Col className='onboard bg-abroad-blue'>
              <div className='h-100 d-flex flex-column align-items-center'>
                <AbroadGoldenIcon />
                <section
                  className={classNames('onboard-wrapper', {
                    'h-lg-65': !conditionMatched,
                  })}>
                  <LoginForm />
                </section>
                <div className='d-flex w-100 justify-content-center align-items-center s8 my-3'>
                  <div className='page-bottom-link'>
                    <span className='text-white'>New to Abroad?&nbsp;</span>
                    <Link to='/account' title='Sign up'>
                      <span>Sign up for free</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default Login
