import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ShimmerText } from 'react-shimmer-effects'
import { useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import API from '../../utils/API'

const YourQuestsToDo = () => {
  const history = useHistory()
  const Error = useErrorService()
  const [todoList, setTodoList] = useState([])
  const { questId } = useParams()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getQuestsToDo = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.quests.questsToDoGet(questId)
        setTodoList(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getQuestsToDo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  const ShimmerEffect = () => {
    return (
      <Row className='my-4'>
        <Col sm={1}>
          <ShimmerText line={1} gap={30} />
        </Col>
        <Col sm={10}>
          <ShimmerText line={1} gap={30} />
        </Col>
        <Col sm={1}>
          <ShimmerText line={1} gap={30} />
        </Col>
      </Row>
    )
  }

  const handleCheckBox = async (index) => {
    try {
      const updatedList = [...todoList]
      updatedList[index].status = !updatedList[index].status
      setTodoList(updatedList)
      const requestData = {
        toDoList: {},
      }
      updatedList.forEach((item) => {
        requestData.toDoList[item.code] = item.status
      })
      await API.quests.questsToDoPut(questId, requestData)
    } catch (e) {
      Error.showError(e)
    }
  }

  const romanize = (num) => {
    const romanNumerals = [
      'i',
      'ii',
      'iii',
      'iv',
      'v',
      'vi',
      'vii',
      'viii',
      'ix',
      'x',
    ]
    return romanNumerals[num - 1] || num.toString()
  }
  return (
    <>
      <Helmet>
        <title>{'TodoList | Abroad'}</title>
        <meta name='title' content={'TodoList | Abroad'}></meta>
        <meta property='og:title' content={'TodoList | Abroad'}></meta>
        <meta property='twitter:title' content={'TodoList | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>
      <div>
        <PageHeader
          showBackArrow={true}
          version='small'
          title='Your Quest'
          backArrowAction={() => history.goBack()}
        />
        <div className='s1 py-16px'>To Do List</div>
        <div className='s5'>
          Use this section of your Quest dashboard to make sure you are fully
          prepared for the trip ahead. As you complete items on the list, simply
          press the checkmark to confirm its completion.
        </div>
        <div className='table-responsive mt-2'>
          {isLoading ? (
            <>
              <div className='overflow-hidden'>
                <ShimmerEffect />
                <ShimmerEffect />
                <ShimmerEffect />
                <ShimmerEffect />
                <ShimmerEffect />
                <ShimmerEffect />
              </div>
            </>
          ) : (
            <Table className='custom-hover align-middle'>
              <tbody className='cursor-pointer'>
                {todoList.map((item, index) => (
                  <tr
                    className='align-middle'
                    style={{ borderBottom: '1px solid #F3F6F6' }}>
                    <td width='7%' className='px-0 py-2'>
                      <div className='s1 me-5'>{romanize(index + 1)}</div>
                    </td>
                    <td width='86%' className='px-0 py-2 font-hover'>
                      <pre
                        className='s6 pre my-auto'
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </td>
                    <td width='7%' className='px-0 py-2'>
                      <div className='text-end'>
                        <div onClick={() => handleCheckBox(index)}>
                          {item.status ? (
                            <svg
                              width='20'
                              height='20'
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <rect
                                width='20'
                                height='20'
                                rx='10'
                                fill='#509F92'
                              />
                              <path
                                d='M8.72498 13.9577C8.62109 14.0622 8.47943 14.1205 8.3322 14.1205C8.18498 14.1205 8.04332 14.0622 7.93943 13.9577L4.80276 10.8205C4.4772 10.4949 4.4772 9.96715 4.80276 9.64215L5.19554 9.24937C5.52109 8.92382 6.04832 8.92382 6.37387 9.24937L8.3322 11.2077L13.6239 5.91604C13.9494 5.59049 14.4772 5.59049 14.8022 5.91604L15.195 6.30882C15.5205 6.63437 15.5205 7.16215 15.195 7.48715L8.72498 13.9577Z'
                                fill='white'
                              />
                            </svg>
                          ) : (
                            <svg
                              width='20'
                              height='20'
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <rect
                                x='0.5'
                                y='0.5'
                                width='19'
                                height='19'
                                rx='9.5'
                                stroke='#509F92'
                              />
                              <path
                                d='M8.72498 13.9577C8.62109 14.0622 8.47943 14.1205 8.3322 14.1205C8.18498 14.1205 8.04332 14.0622 7.93943 13.9577L4.80276 10.8205C4.4772 10.4949 4.4772 9.96715 4.80276 9.64215L5.19554 9.24937C5.52109 8.92382 6.04832 8.92382 6.37387 9.24937L8.3322 11.2077L13.6239 5.91604C13.9494 5.59049 14.4772 5.59049 14.8022 5.91604L15.195 6.30882C15.5205 6.63437 15.5205 7.16215 15.195 7.48715L8.72498 13.9577Z'
                                fill='#509F92'
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  )
}

export default YourQuestsToDo
