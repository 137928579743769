import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {
  Button,
  useNotificationService,
  useErrorService,
  FeatherLoader,
  useUserService,
} from '@abroad/components'
import { useHistory, useLocation } from 'react-router-dom'
import BackIcon from './BackIcon'
import API from '../../utils/API'
import {
  localStorageCustomSurvey,
  customSurveyRequestId,
} from '../../utils/constants'
import TextArea from './TextArea'
const Custom360 = () => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [client, setClient] = useState()
  const history = useHistory()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const { user } = useUserService()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const queryCustomSurveyRequestId = query.get(customSurveyRequestId)
  const userId = user?._id
  const csrId = queryCustomSurveyRequestId || localStorage.getItem(localStorageCustomSurvey)
  const custom_Survey_answer_storage_key = `customSurveyAnswers_of_${userId}_${csrId}`

  useEffect(() => {
    const getCustom360Request = async () => {
      try {
        if (csrId) {
          const { data } = await API.custom360.custom360RequestId(csrId)
          setClient(data.client)
          const storedAnswers = JSON.parse(localStorage.getItem(custom_Survey_answer_storage_key)) || {}
          const questionsWithAnswers = data.questions.map((q) => ({
            ...q,
            ans: storedAnswers[q._id] || '',
          }))
          setQuestions(questionsWithAnswers)
          setIsLoading(false)
        } else {
          history.replace('/home')
        }
      } catch (e) {
        if (e?.code === 'conflict') {
          localStorage.removeItem(localStorageCustomSurvey)
          Notification.showNotification(
            'You have already submitted your feedback.',
            'danger',
          )
          history.replace('/home')
        } else if (e?.code === 'resource_not_found') {
          localStorage.removeItem(localStorageCustomSurvey)
          history.replace('/home')
          Error.showError(e)
        } else {
          Error.showError(e)
          setIsLoading(false)
        }
      }
    }
    getCustom360Request()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCustomSurveyRequestId])

  const handleTextAreaChange = (event) => {
    const textarea = event.target
    textarea.style.height = '2.5rem'
    textarea.style.height = textarea.scrollHeight + 'px'
    handleChange(textarea.value)
  }

  const handleChange = (ans) => {
    const updatedQuestions = [...questions]
    updatedQuestions[activeIndex].ans = ans
    setQuestions(updatedQuestions)
    const storedAnswers = JSON.parse(localStorage.getItem(custom_Survey_answer_storage_key)) || {}
    storedAnswers[questions[activeIndex]._id] = ans
    localStorage.setItem(custom_Survey_answer_storage_key,JSON.stringify(storedAnswers))
  }

  const handleNext = () => {
    if (activeIndex === null) {
      if (questions.length > 0) {
        setActiveIndex(0)
      } else {
        Notification.showNotification(
          'Something went wrong! Please try again later.',
          'danger',
        )
      }
    } else {
      setActiveIndex((prev) => prev + 1)
    }
  }

  const handlePrevious = () => {
    if (activeIndex > 0) {
      setActiveIndex((prev) => prev - 1)
    } else {
      setActiveIndex(null)
    }
  }

  const onSubmit = async (data) => {
    try {
      setSubmitting(true)
      const ans = questions.map((q) => ({ text: q.ans, questionId: q._id }))
      await API.custom360.custom360Answers(client._id, { answers: ans })
      localStorage.removeItem(custom_Survey_answer_storage_key)
      localStorage.removeItem(localStorageCustomSurvey)
      Notification.showNotification(
        'Your feedback has been submitted!',
        'success',
      )
      history.replace('/home')
    } catch (e) {
      setSubmitting(false)
      Error.showError(e)
    }
  }

  const custom360Info = () => {
    return (
      <>
        <div className='s1 font-white mb-3' style={{ letterSpacing: '0px' }}>
          {`Welcome to ${client?.fname}’s Leadership Feedback Questionnaire`}
        </div>
        <p className='s13 font-white mb-3'>
          {`Thank you for participating in this important feedback process. Your insights will help us understand and enhance ${client?.fname}'s leadership qualities. Your honest feedback is highly appreciated and will remain confidential.`}
          <br />
          <br />
          {`To begin, please answer a series of ${questions.length} questions about ${client?.fname}'s leadership. Each question will be presented one at a time, and your responses will be shared only with ${client?.fname}’s coach.`}
        </p>
        <Button
          variant='saffron'
          onClick={handleNext}
          className='border-radius-normal px-36px py-14px s9 mt-5'>
          BEGIN
        </Button>
      </>
    )
  }
  const renderWritten = (length) => {
    return (
      <>
        <div>
          <TextArea
            value={
              questions[activeIndex]?.ans ? questions[activeIndex]?.ans : ''
            }
            onChange={handleTextAreaChange}
          />
          <div className='custom-border mb-5'></div>
        </div>
      </>
    )
  }

  const renderNumerical = (length) => {
    return (
      <>
        <div className='mt-40px'>
          <div className='grid-box'>
            {['1', '2', '3', '4', '5', '6'].map((value, index) => (
              <div key={value}>
                <Button
                  className={`rounded s14 p-0 pe-auto ${
                    questions[activeIndex]?.ans === value ? 'selected' : ''
                  }`}
                  style={{ width: '100%', height: '52px' }}
                  variant={
                    questions[activeIndex]?.ans === value
                      ? 'saffron'
                      : 'outline-dark'
                  }
                  onClick={() => handleChange(value)}>
                  {value}
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-24px'>
          <p className='s9 font-white'>STRONGLY DISAGREE</p>
          <p className='s9 font-white'>STRONGLY AGREE </p>
        </div>
      </>
    )
  }

  const renderQuestion = () => {
    return (
      <>
        <h6 className='s10a text-saffron-300 mb-3'>
          {activeIndex + 1}/{questions.length}
        </h6>
        <div
          className='s1 font-white mb-40px text-break'
          style={{ letterSpacing: '0px' }}>
          {questions &&
            questions[activeIndex] &&
            questions[activeIndex].text?.replaceAll(
              '@firstname',
              client?.fname,
            )}
        </div>
        {questions[activeIndex]?.type === 'written'
          ? renderWritten()
          : renderNumerical()}
        <div className='mt-80px'>
          <Button
            variant='outline-dark'
            onClick={handlePrevious}
            style={{
              opacity: isSubmitting ? 0.5 : 1,
              cursor: isSubmitting ? 'not-allowed' : 'pointer',
            }}
            className='border-radius-normal px-36px py-14px s9 rounded mr-24px mb-lg-0 mb-sm-2 mb-2'>
            PREVIOUS
          </Button>
          <Button
            variant='saffron'
            disabled={!questions[activeIndex]?.ans || isSubmitting}
            isLoading={isSubmitting}
            onClick={
              activeIndex === questions.length - 1 ? onSubmit : handleNext
            }
            className='border-radius-normal px-36px py-14px s9 mb-lg-0 mb-sm-2 mb-2'>
            {activeIndex === questions.length - 1
              ? 'SUMBIT AND RETURN TO PLATFORM'
              : 'NEXT'}
          </Button>
        </div>
      </>
    )
  }

  return (
    <Container fluid className='px-0'>
      <div className='vh-100 row g-0'>
        <div className='onboard bg-abroad-blue position-relative pb-5'>
          <BackIcon />
          <div className='d-flex justify-content-center align-items-center mt-120px'>
            <Container>
              <Row>
                <Col md={{ span: 8, offset: 2 }}>
                  {isLoading ? (
                    <FeatherLoader width={50} />
                  ) : activeIndex === null ? (
                    custom360Info()
                  ) : (
                    renderQuestion()
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Custom360
