import React from 'react'
import Imgix from 'react-imgix'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/pagination/pagination.scss'
import '../../styles/customSwiper.scss'
import { useResolutionService } from '../../hooks'

const SwiperSlideQuests = () => {
  const { isMDScreen } = useResolutionService()
  return (
    <div>
      <Swiper
        slidesPerView={3}
        navigation
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.1,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 2.5,
            spaceBetween: 40,
          },
          2560: {
            slidesPerView: 2.5,
            spaceBetween: 80,
          },
        }}
        className='overflow-xy custom-swiper'>
        <SwiperSlide>
          {' '}
          <div style={{ width: '100%' }}>
            <Imgix
              className='border-radius-10 mt-4 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:3',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticQuest1.jpg`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/StaticQuest1.jpg?ar=${
                  isMDScreen ? '2:1' : '5:3'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h4 className='s10a pt-16px font-saffron-700'>WEEKS 1-5</h4>
            <h2 className='s2 fst-italic'>
              Pre-Trip Coaching &<br /> Preparation
            </h2>
            <p className='s6 pt-16px'>
              Before you embark on your quest, our team of 
              experienced coaches will work with you one-on-one and 
              in group sessions to prepare you for the transformative 
              journey ahead. Complete our comprehensive 
              psychometric assessments to provide a baseline of your 
              current leadership strengths and areas for development.
            </p>{' '}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <div style={{ width: '100%' }}>
            <Imgix
              className='border-radius-10 mt-4 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:3',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticQuest2.png`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/StaticQuest2.png?ar=${
                  isMDScreen ? '2:1' : '5:3'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h4 className='s10a mt-3 font-saffron-700'>WEEKS 6-7</h4>
            <h2 className='s2 fst-italic'>
              Location Based <br /> Experiential Learning
            </h2>
            <p className='s6'>
            Immerse yourself in a truly unique experiential learning 
            environment as you interact and engage with visionary 
            leaders, world-renowned faculty, and local wisdom 
            cultures. Explore foundational concepts such as 
            compassion, mindfulness, vision, and contentment—
            taught by the cultures that embody these principles. 
            Then, with guidance from our esteemed faculty, discover 
            how to integrate these values into your daily leadership 
            roles and professional settings.
            </p>{' '}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <div style={{ width: '100%' }}>
            <Imgix
              className='border-radius-10 mt-4 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                ar: isMDScreen ? '2:1' : '5:3',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/StaticQuest3.jpg`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/StaticQuest3.jpg?ar=${
                  isMDScreen ? '2:1' : '5:3'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <h4 className='s10a mt-3 font-saffron-700'>WEEKS 8-12</h4>
            <h2 className='s2 fst-italic'>
              Post-Trip Integration <br /> & Certification
            </h2>
            <p className='s6'>
              Return from your journey and continue to grow through 
              individual and group coaching sessions, leveraging 
              insights from your trip to catalyze profound shifts in your 
              life and leadership. As part of your quest, you will have 
              lifetime access to our mobile app to help further your 
              learning. For those engaged in continuing education 
              programs through your organization, you will be 
              recognized with a graduation certificate.
            </p>{' '}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default SwiperSlideQuests
