import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import Imgix from 'react-imgix'
import { ABSwiperSlider } from '../slider'

const QuestsLearningResourcesBlog = ({ category, questId }) => {
  const history = useHistory()
  const handleBlogClick = (blogId) => {
    history.push(
      `/your-quest/${questId}/learning-resources/${blogId}/blog-details`,
    )
  }
  const renderBlogMedia = (blog, style = {}) => {
    return (
      <div key={blog?._id}>
        <div
          className='cursor-pointer'
          style={style}
          onClick={() => handleBlogClick(blog?._id)}>
          <div className='position-relative border-radius-10 overflow-hidden'>
            <Imgix
              className='lazyload border-radius-10 img-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                w: 400,
                h: 400,
                ar: '1:1',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/blogs/${blog?.image}`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/blogs/${blog?.image}?fit=crop&fill=blur&h=400&w=400&ar=1:1&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
          </div>
        </div>
        <div className='d-block mt-15px mb-3px'>
          <div
            className={classNames('s7 text-truncate', {
              'cursor-pointer': blog.isUpgradeRequired,
            })}>
            {blog.title}
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <Container className={classNames(`p-0 mb-52px`)} fluid>
        <Row className='g-0'>
          <Col className='g-0'>
            <div className='s3 mb-3'>Blog</div>
            {category.length > 0 && (
              <ABSwiperSlider containerClassName='card-swiper'>
                {category.map((blog) => renderBlogMedia(blog))}
              </ABSwiperSlider>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default QuestsLearningResourcesBlog
