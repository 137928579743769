import React from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../common'
import QuestSession from './QuestSession'
import QuestIntentionInsight from './QuestIntentionInsight'

const QuestsIntention = () => {
  const history = useHistory()
  return (
    <>
      <Helmet>
        <title>{'Intention & Insight | Abroad'}</title>
        <meta name='title' content={'Intention & Insight | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Intention & Insight | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Intention & Insight| Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>
      <div>
        <PageHeader
          showBackArrow={true}
          version='small'
          title='Your Quest'
          backArrowAction={() => history.goBack()}
        />
        <div className='s1 py-16px'>Intentions & Insights</div>
        <QuestSession />

        <QuestIntentionInsight />
      </div>
    </>
  )
}

export default QuestsIntention
